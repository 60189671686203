/* .ant-table-row {
  background-color: blue !important;
} */


.clickRowStyl {
  background-color:#00000040 !important;
  font-size: 12px !important;
}
.rowComment {
  font-size: 12px !important;
}
/* .ant-table-tbody>.clickRowStyl:hover>td {
  background-color: '#00b4ed' !important
} */

.linkwhatsapp {
  background-image: url(../../images/whatsapp-icon.svg);
  background-repeat: no-repeat;
  background-position-x: 0;
  padding-top:3px;
  padding-bottom:3px;
  padding-left: 28px;
}
