.card-redes-sociais{
    background-color: #cfe8f5 !important;
}

.card-redes-sociais input {
    margin-bottom: 1rem;
}

.ant-checkbox-wrapper{
    margin-top: 0.3rem !important;
}