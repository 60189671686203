.content {
  margin: -10px 16px 24px;
  padding: 24px;
  height: 86vh;
}

.content-blue {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
}

.content-blue h1 {
  font-size: 60px;
  font-weight: bold;
  letter-spacing: 5px;
}

@media (max-width: 800px) {
  .content-blue h1 {
    font-size: 2em;
  }
}

.home-charts {
  margin-top: 5px;
  margin-bottom: 5px;
}

.home-charts div {
  background: #fff;
  border: 1px solid #e8e8e8;
  margin-top: -5px;
  margin-bottom: -5px;
}

.ant-card-head-title {
  font-size: 14px !important;
  font-weight: 700;
}

.ant-layout.ant-layout-has-sider {
  -webkit-flex-direction: row;
  flex-direction: row;
  height: 100vh;
}

/* .anticon {
  color: #fff !important;
} */

.ant-layout-sider {
  background: #0a2851 !important;
}

.ant-layout-sider-collapsed .logo {
  width: 50px !important;
  transition: all 0.15s;
}

.welcome {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.logo {
  height: 28px;
  margin: 16px;
  /* width: 165px !important; */
}

.logoWelcome {
  height: 65px;
  margin: 16px;
}

.logo .logo-image {
  margin-top: 0px;
  height: 32px;
  /* width: 165px; */
}

.logo .logo-icon {
  height: 50px;
  width: 50px;
  margin-left: 0px;
  margin-top: -10px;
}

.ant-menu-dark,
.ant-menu-dark .ant-menu-sub {
  background: #0a2851 !important;
}

@media (max-width: 800px) {
  .content {
    padding: 0px;
  }
}

@media (min-width: 992px) {
  .custom-col-5 {
    width: 20%;
  }
}
