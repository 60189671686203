.form-content-template {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}

.form-template{
    display: flex;
    flex-direction: column;
    width: 50%;
    justify-content: center;
    align-items: flex-start;
    border-right: 1px solid #ccc;
    padding: 10px;
}

.form-template label {
    font-weight: bold;
}

.form-template label.delete {
    font-weight: bold;
    color: red;
    cursor: pointer;
    text-decoration: underline;
    font-size: 16px;
}

.form-template img {
    width: 50%;
    height: 50%;
}

.form-template span.info {
    font-style: italic;
}