.btn-rede-social{
    background-color: transparent !important;
    border: none !important;
    margin-top: 18px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.btn-avaliacao-externa{
    margin-bottom: 12px;
    flex-direction: column;
}

.btn-avaliacao-externa label {
    font-size: 13px;
    width: 100%;
    display: block;
    text-align: left;
}

.btn-rede-social img {
    width: 150px;
    border-radius: 6px;
}

.btn-rede-social img:hover{
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.div-follow-us{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    max-width: 600px;
    margin: auto;
}

.follow-us img{
    width: 45px;
    height: 45px;
}

.follow-us label {
    font-size: 16px;
    cursor: pointer;
}

.orientacao-rede-social {
    font-size: 12px;
    margin-top: 10px;
    font-weight: bold;
}

@media screen and (max-width: 480px) {
    .div-follow-us {
        align-items: flex-start;
    }
    .btn-rede-social img {
        width: 100px;
    }

    .follow-us img{
        width: 45px;
        height: 45px;
    }
}