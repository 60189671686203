@import "~antd/dist/antd.css";

.App {
  text-align: center;
}

.ant-message, .ant-popover {
  z-index: 999999999 !important;
}

.logo-cliente {
  height: auto; 
  width: auto; 
  max-width: 200px; 
  max-height: 120px;
}
