.upload-logo{
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.upload-logo img {
    margin-top: 10px;
    margin-bottom: 10px;
    width: 200px;
    height: 120px;
}

.upload-logo input {
    visibility: hidden;
    cursor: pointer;
}

.upload-logo label {
    cursor: pointer;
    text-decoration: underline;
    font-weight: bold;
    color: #0a2851;
    font-size: 16px;
}

.upload-logo label:last-child {
    color: red;
}