.header {
  position: fixed;
  width: 100% !important;
  margin: 0 !important;
  padding: 0 !important;
  padding-left: 1rem !important;
  z-index: 9999;
  background-color: #84c2e4 !important;
}

@media only screen and (min-width: 1440px) {
  .header {
    padding: 0 1rem !important;
  }
}

.header span,
.header h1 {
  color: #3c4441 !important;
  font-size: 1rem;
}

.header span {
  cursor: pointer;
  transition: all 0.25s;
}

.header span:hover {
  color: #00a1ed;
}

.user-logged {
  position: inherit;
  margin-right: 24px;
  right: 0;
  color: #fff;
}

.trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
  color: rgba(255, 255, 255, 1);
  display: none;
}

.trigger:hover {
  color: rgba(255, 255, 255, 0.5);
}

.logo {
  height: 28px;
  margin: 16px;
  /* width: 165px !important; */
}

.logo .logo-image {
  margin-top: 0px;
  height: 32px;
  /* width: 165px; */
}

.logo-icon {
  height: 50px;
  width: 50px;
  margin-left: -13px;
  margin-top: -10px;
}

.btnChangeEmpresa {
  border: 0;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  border-radius: 6px;
  cursor: pointer;
  background-color: transparent;
  color: #3c4441;
  margin-top: -5px;
}

.btnChangeEmpresa:hover {
  background-color: #3c4441;
  color: white;
  transition: 1s;
}

.select-unidade {
  width: 300px;
  height: 30px;
  outline: 0;
  border-radius: 8px;
  cursor: pointer;
  background-color: #f0f2f5;
  color: rgba(0, 0, 0, 0.65);
}

@media (max-width: 800px) {
  .trigger {
    display: none;
  }

  .select-unidade{
    width: 200px;
  }
}

@media (max-width: 500px) {
  .trigger {
    display: none;
  }
  .select-unidade{
    margin-top: 4px;
    margin-bottom: 4px;
    width: 200px;
  }
  .header{
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    flex-direction: column-reverse;
    line-height: 6px !important;
    padding-top: 2px !important
  }
}
