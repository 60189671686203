.sider {
  position: fixed;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100vh;
  width: 80px; /* 200px */
}

.sider .ant-menu-item {
  width: 100% !important;
  min-height: 80px !important;
  margin: 0.5rem 0 !important;
  padding: 0 !important;
}

.sider .ant-menu-item a {
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;

  width: 100% !important;
  height: 100%;
  font-size: 0.8rem;
  color: #e7e7e7 !important;

  line-height: 1.5rem;
}

.helpButton {
  color: #e7e7e7 !important;
}

.sider .ant-menu-item span {
  margin: 0 auto;
}

.sider .ant-menu-item i {
  font-size: 1.2rem;
  margin: 0;
}

.sider .ant-menu-item img {
  width: 1.7rem;
  height: 1.7rem;
  margin: 0 auto;
}

.sider a:hover,
.sider a:active {
  text-decoration: inherit !important;
  background-color: #293039;
}

.ant-layout-sider-children {
  background: #0e1318 !important;
}

.ant-layout-sider {
  z-index: 999 !important;
}

.ant-layout-sider ul,
.ant-layout-sider li {
  background: #0e1318 !important;
  width: calc(100% + 1px);
}

.ant-layout-sider .ant-menu-item-selected {
  background: #00a1ed !important;
  width: 100% !important;
}

.ant-layout-sider-collapsed .logo {
  width: 50px !important;
  transition: all 0.15s;
}

.ant-layout-sider-collapsed ul {
  width: 81px !important;
}
.ant-layout-sider-collapsed li {
  width: 80px !important;
}

.logo {
  height: 28px;
  margin: 16px;
  /* width: 165px !important; */
}

.logo .logo-image {
  margin-top: 0px;
  height: 32px;
  /* width: 165px; */
}

.logo .logo-icon {
  height: 80px;
  width: 80px;
  margin-left: 0px;
  margin-top: -10px;
}


