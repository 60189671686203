.content {
  margin: -10px 16px 24px;
  padding: 24px;
  height: 100%;
}

.table .ant-table-row {
  background: #fff;
}

.table-actions {
  padding: 6px 12px;
  margin: -3px 0;
  cursor: pointer;
  transition: all 0.25s;
}

.table-actions:hover {
  color: #0cd28f;
}

.nopadding-col div {
  padding: 0;
}

.col-action {
  text-align: right !important;
}

.new-item {
  margin: 77px 16px 0px !important;
  padding: 12px !important;
  text-align: right;
}

.btn-add {
  width: inherit;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ant-card-actions {
  background: #ececec !important;
  border-top: 1px solid #fafafa !important;
}

.ant-card-head {
  border-bottom: 1px solid #fafafa !important;
}

@media (max-width: 800px) {
  .content {
    padding: 0px;
  }
}
@media (max-width: 768px) {
  .new-item {
    margin: 0px 16px 20px !important;
    text-align: left;
  }
  .btn-add {
    width: 100%;
  }
}

@media (max-width: 576px) {
  .hidden-xs {
    display: none;
  }
}
