.content {
  margin: -10px 16px 24px;
  padding: 24px;
  height: 86vh;
}

.content-card {
  margin-top: 100px;
}

.content-card h1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 60px;
  font-weight: bold;
  letter-spacing: 5px;
}

@media (max-width: 800px) {
  .content-card h1 {
    font-size: 2em;
    margin-top: 50px;
  }
}

.home-charts {
  margin-top: 5px;
  margin-bottom: 5px;
}

.home-charts div {
  background: #fff;
  border: 1px solid #e8e8e8;
  margin-top: -5px;
  margin-bottom: -5px;
}

.ant-card-head-title {
  font-size: 14px !important;
  font-weight: 700;
}

.ant-layout.ant-layout-has-sider {
  -webkit-flex-direction: row;
  flex-direction: row;
  height: 100vh;
}

/* .anticon {
  color: #fff !important;
} */

.ant-layout-sider-collapsed .logo {
  width: 50px !important;
  transition: all 0.15s;
}

.welcome {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.logo {
  height: 28px;
  margin: 16px;
  /* width: 165px !important; */
}

.logoWelcome {
  height: 65px;
  margin: 16px;
}

.logo .logo-image {
  margin-top: 0px;
  height: 32px;
  /* width: 165px; */
}

.logo .logo-icon {
  height: 50px;
  width: 50px;
  margin-left: 0px;
  margin-top: -10px;
}

@media (max-width: 800px) {
  .content {
    padding: 0px;
  }
}

@media (min-width: 992px) {
  .custom-col-5 {
    width: 20%;
  }
}

.stats {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem;
}

.statsCard {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  width: 12rem;
  min-height: 15rem;

  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 0px 3px 2px rgba(0, 0, 0, 0.1);
}

.up {
  padding: 0.5rem;
  text-align: center;
}

.up span {
  font-size: 3rem;
}

.up h3 {
  margin: 0;
}

.statsIcon {
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  margin: 0 auto;

  background-color: #f8f8f8;

  display: flex;
  justify-content: center;
  align-items: center;
}

.statsIcon img {
  width: 20px;
}

.bottom {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #f8f8f8;
  border-radius: 0 0 10px 10px;
  padding: 0.5rem;
  min-height: 5rem;
}

.bottom p {
  margin: 0;
  margin-bottom: 0.5rem;
}

.between {
  display: flex;
  justify-content: space-between;
}

.green {
  color: #1bc865;
}

.yellow {
  color: #e6c850;
}

.red {
  color: red;
}

.latestRates {
  display: flex;

  flex-direction: column;
  gap: 0.7rem;

  background-color: white;
  box-shadow: 0px 0px 3px 2px rgba(0, 0, 0, 0.1);

  max-width: 55rem;
  margin: 4rem auto;

  border-radius: 10px;
  padding: 2rem;
}

.latestRates h2 {
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
}

.rates {
  width: 100%;
}

.rate {
  width: 100%;
  font-size: 1rem;
  padding: 0 0.3rem 0.3rem 0.3rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  display: flex;
  margin-top: 5px;
}

.rate .span25 {
  min-width: 25%;
  max-width: 25%;
}

.rate .span20 {
  min-width: 20%;
  max-width: 20%;
}

.classification {
  font-weight: 600;
  padding: 2px;
  display: block;
  text-align: center;

  background-color: #1bc865;
}

.bgGreen {
  background-color: #1bc865;
}

.bgYellow {
  background-color: #e6c850;
}

.bgRed {
  background-color: #ff6565;
}

.latestComments {
  background-color: white;
  box-shadow: 0px 0px 3px 2px rgba(0, 0, 0, 0.1);

  max-width: 55rem;
  margin: 4rem auto;

  border-radius: 10px;
  padding: 2rem;
}

.latestComments h2 {
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  font-weight: 500;
}

.latestComments p {
  font-weight: normal !important;
  text-align: center;
  font-size: 1.7rem;
}

.positive {
  background-color: #5ee497;
  width: 100%;
  min-width: 30rem;
}

.negative {
  width: 100%;
  min-width: 30rem;
  background-color: #ffb8b3;
}

.phrases {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem 1rem 1rem;
}

.phrase {
  max-width: 70ch;
  font-size: 1rem;
  font-weight: 600;
  padding-right: 3rem;

  text-align: justify;
}

.userData {
  display: flex;
  flex-direction: column;
  text-align: right;
}

@media only screen and (max-width: 840px) {
  .rate {
    display: block;
  }

  .rate .span20, .rate .span25 {
    min-width: none;
    max-width: 100%;
    margin: 5px;
    display: block;
  }

  .saudacao div span {
    font-size: 12px;
    width: 100%;
  }
}

@media screen and (max-width: 960px){
  .saudacao div span {
    font-size: 16px;
    
  }
}

@media screen and (max-width: 370px){
  .saudacao div span {
    font-size: 14px;
    width: 100%;
    text-align: center;
  }
}

