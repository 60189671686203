.login-page {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #f0f2f5;
}

.wrapper {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  overflow: hidden;
  transform: translateY(-50%);
}

.container {
  width: 90%;
  max-width: 400px;
  margin: 0 auto;
  padding: 40px;
  text-align: center;
  background: #fff;
}
.container .logo-login {
  height: 70px;
  width: 70px;
  margin: 0 0 10px;
}

.container .ant-input-affix-wrapper {
  text-align: left;
  margin: 10px 0 10px;
}

.container button {
  margin: 15px 0 0;
  background-color: #0a2851;
}
