.ant-form-item {
  margin-bottom: 21px !important;
}

.ant-btn-primary {
  background: #0a2851 !important;
}

.ant-switch-checked {
  background: #0a2851 !important;
}
