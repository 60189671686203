.imageContent {
  width: 100%;
  margin: 0 0 10px;
}

.container-image {
  width: 80%;
  max-width: 600px;
  margin: 0 auto;
  padding: 40px;
  text-align: center;
  background: #fff;
}
