.container-form {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}

.content-left {
  height: 100vh;
}

.content-left .link-blue {
  margin: 10px 20px;
}

.content-right {
  height: 100vh;
}

.registerUser {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 150px;
}

.form-page {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff;
}

.wrapper {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  overflow: hidden;
  transform: translateY(-50%);
}

.container {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  padding: 40px;
  text-align: center;
  background: #fff;
}
.container .logo-login {
  height: 70px;
  width: 70px;
  margin: 0 0 10px;
}

.logo-blue {
  height: 70px;
  width: 70px;
  margin: 0 0 10px;
}

.container .ant-input-affix-wrapper {
  text-align: left;
  margin: 10px 0 10px;
}

.container button {
  margin: 15px 0 0;
  background-color: #0a2851;
  width: 100%;
}

.title-welcome {
  font-size: 40px;
  font-weight: 100;
}

.subtitle-welcome {
  font-size: 20px;
  font-weight: 300;
}

@media screen and (max-width: 600px) {
  .title-welcome {
    font-size: 20px;
    font-weight: 100;
  }
  .subtitle-welcome {
    font-size: 18px;
    font-weight: 300;
  }
  .contentImage {
    display: none;
  }
}

@media screen and (max-width: 400px) {
  .title-welcome {
    font-size: 20px;
    font-weight: 100;
  }
  .subtitle-welcome {
    font-size: 18px;
    font-weight: 300;
  }
  .contentImage {
    display: none !important;
  }
}
