.ant-layout-content {
  -webkit-flex: auto;
  flex: auto;
  min-height: auto !important;
}

.ant-collapse > .ant-collapse-item:last-child {
  border-radius: 0 0 4px 4px;
  font-size: 12px;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  font-size: 12px;
}
