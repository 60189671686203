.steps-content {
	margin-top: 16px;
	border: 1px dashed #e9e9e9;
	border-radius: 6px;
	background-color: #fafafa;
	min-height: 560px;
	text-align: center;
	padding-top: 80px;
}

.steps-action {
	margin-top: 24px;
  margin-bottom: 40px;
}

@media (max-width: 500px) {
	.ant-steps-horizontal.ant-steps-label-horizontal {
		display: flex !important;
	}
}
@media (max-width: 480px) {
	.ant-steps-item-tail, .ant-steps-item-title, .ant-steps-item-content {
		display: none !important;
	}
}

.indice-blue {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	cursor: pointer;
	background: #ff3232; /* Old browsers */
	background: -moz-linear-gradient(left,  #ff3232 0%, #ccc620 50%, #00bc06 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(left,  #ff3232 0%,#ccc620 50%,#00bc06 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to right,  #ff3232 0%,#ccc620 50%,#00bc06 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff3232', endColorstr='#00bc06',GradientType=1 ); /* IE6-9 */
}

.indice-blue-item {
	flex: 1;
	color: #fff;
	padding: 5px;
	border: #fff 1px solid;
	text-align: center;
}

.indice-blue-item.selected {
	font-weight: 900;
	background: rgba(255, 255, 255, .5);
	color: #fff;
}

.next-step:disabled {
  background: #eee !important;
  color: #aaa !important;
  border-color: #aaa !important;
}
