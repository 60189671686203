.logo {
  height: 28px;
  margin: 16px;
  /* width: 165px !important; */
  text-align: center;
}

.logo .logo-image {
  margin-top: 0px;
  height: 32px;
  /* width: 165px; */
}

.logo .logo-icon {
  height: 50px;
  width: 50px;
  margin-left: 0px;
  margin-top: -10px;
}
