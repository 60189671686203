.breadcrumb {
  margin: 84px 16px 0px !important;
  padding: 12px !important;
  font-size: 20px !important;
  font-weight: bold !important
}

.breadcrumb span {
  position: relative;
  float: left;
}

@media (max-width: 800px) {
  .breadcrumb {
    margin: 84px 16px 20px !important;
  }
}
