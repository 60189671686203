@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap);
body {
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.header {
  position: fixed;
  width: 100% !important;
  margin: 0 !important;
  padding: 0 !important;
  padding-left: 1rem !important;
  z-index: 9999;
  background-color: #84c2e4 !important;
}

@media only screen and (min-width: 1440px) {
  .header {
    padding: 0 1rem !important;
  }
}

.header span,
.header h1 {
  color: #3c4441 !important;
  font-size: 1rem;
}

.header span {
  cursor: pointer;
  transition: all 0.25s;
}

.header span:hover {
  color: #00a1ed;
}

.user-logged {
  position: inherit;
  margin-right: 24px;
  right: 0;
  color: #fff;
}

.trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
  color: rgba(255, 255, 255, 1);
  display: none;
}

.trigger:hover {
  color: rgba(255, 255, 255, 0.5);
}

.logo {
  height: 28px;
  margin: 16px;
  /* width: 165px !important; */
}

.logo .logo-image {
  margin-top: 0px;
  height: 32px;
  /* width: 165px; */
}

.logo-icon {
  height: 50px;
  width: 50px;
  margin-left: -13px;
  margin-top: -10px;
}

.btnChangeEmpresa {
  border: 0;
  height: 30px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  margin-left: 10px;
  border-radius: 6px;
  cursor: pointer;
  background-color: transparent;
  color: #3c4441;
  margin-top: -5px;
}

.btnChangeEmpresa:hover {
  background-color: #3c4441;
  color: white;
  transition: 1s;
}

.select-unidade {
  width: 300px;
  height: 30px;
  outline: 0;
  border-radius: 8px;
  cursor: pointer;
  background-color: #f0f2f5;
  color: rgba(0, 0, 0, 0.65);
}

@media (max-width: 800px) {
  .trigger {
    display: none;
  }

  .select-unidade{
    width: 200px;
  }
}

@media (max-width: 500px) {
  .trigger {
    display: none;
  }
  .select-unidade{
    margin-top: 4px;
    margin-bottom: 4px;
    width: 200px;
  }
  .header{
    display: -webkit-flex;
    display: flex;
    position: relative;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-flex-direction: column-reverse;
            flex-direction: column-reverse;
    line-height: 6px !important;
    padding-top: 2px !important
  }
}

.sider {
  position: fixed;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100vh;
  width: 80px; /* 200px */
}

.sider .ant-menu-item {
  width: 100% !important;
  min-height: 80px !important;
  margin: 0.5rem 0 !important;
  padding: 0 !important;
}

.sider .ant-menu-item a {
  display: -webkit-flex !important;
  display: flex !important;
  -webkit-flex-direction: column !important;
          flex-direction: column !important;
  -webkit-justify-content: center !important;
          justify-content: center !important;

  width: 100% !important;
  height: 100%;
  font-size: 0.8rem;
  color: #e7e7e7 !important;

  line-height: 1.5rem;
}

.helpButton {
  color: #e7e7e7 !important;
}

.sider .ant-menu-item span {
  margin: 0 auto;
}

.sider .ant-menu-item i {
  font-size: 1.2rem;
  margin: 0;
}

.sider .ant-menu-item img {
  width: 1.7rem;
  height: 1.7rem;
  margin: 0 auto;
}

.sider a:hover,
.sider a:active {
  text-decoration: inherit !important;
  background-color: #293039;
}

.ant-layout-sider-children {
  background: #0e1318 !important;
}

.ant-layout-sider {
  z-index: 999 !important;
}

.ant-layout-sider ul,
.ant-layout-sider li {
  background: #0e1318 !important;
  width: calc(100% + 1px);
}

.ant-layout-sider .ant-menu-item-selected {
  background: #00a1ed !important;
  width: 100% !important;
}

.ant-layout-sider-collapsed .logo {
  width: 50px !important;
  transition: all 0.15s;
}

.ant-layout-sider-collapsed ul {
  width: 81px !important;
}
.ant-layout-sider-collapsed li {
  width: 80px !important;
}

.logo {
  height: 28px;
  margin: 16px;
  /* width: 165px !important; */
}

.logo .logo-image {
  margin-top: 0px;
  height: 32px;
  /* width: 165px; */
}

.logo .logo-icon {
  height: 80px;
  width: 80px;
  margin-left: 0px;
  margin-top: -10px;
}



.breadcrumb {
  margin: 84px 16px 0px !important;
  padding: 12px !important;
  font-size: 20px !important;
  font-weight: bold !important
}

.breadcrumb span {
  position: relative;
  float: left;
}

@media (max-width: 800px) {
  .breadcrumb {
    margin: 84px 16px 20px !important;
  }
}

.content {
  margin: -10px 16px 24px;
  padding: 24px;
  height: 86vh;
}

.content-card {
  margin-top: 100px;
}

.content-card h1 {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  font-size: 60px;
  font-weight: bold;
  letter-spacing: 5px;
}

@media (max-width: 800px) {
  .content-card h1 {
    font-size: 2em;
    margin-top: 50px;
  }
}

.home-charts {
  margin-top: 5px;
  margin-bottom: 5px;
}

.home-charts div {
  background: #fff;
  border: 1px solid #e8e8e8;
  margin-top: -5px;
  margin-bottom: -5px;
}

.ant-card-head-title {
  font-size: 14px !important;
  font-weight: 700;
}

.ant-layout.ant-layout-has-sider {
  -webkit-flex-direction: row;
  flex-direction: row;
  height: 100vh;
}

/* .anticon {
  color: #fff !important;
} */

.ant-layout-sider-collapsed .logo {
  width: 50px !important;
  transition: all 0.15s;
}

.welcome {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.logo {
  height: 28px;
  margin: 16px;
  /* width: 165px !important; */
}

.logoWelcome {
  height: 65px;
  margin: 16px;
}

.logo .logo-image {
  margin-top: 0px;
  height: 32px;
  /* width: 165px; */
}

.logo .logo-icon {
  height: 50px;
  width: 50px;
  margin-left: 0px;
  margin-top: -10px;
}

@media (max-width: 800px) {
  .content {
    padding: 0px;
  }
}

@media (min-width: 992px) {
  .custom-col-5 {
    width: 20%;
  }
}

.stats {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: center;
          justify-content: center;
  grid-gap: 2rem;
  gap: 2rem;
}

.statsCard {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-between;
          justify-content: space-between;

  width: 12rem;
  min-height: 15rem;

  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 0px 3px 2px rgba(0, 0, 0, 0.1);
}

.up {
  padding: 0.5rem;
  text-align: center;
}

.up span {
  font-size: 3rem;
}

.up h3 {
  margin: 0;
}

.statsIcon {
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  margin: 0 auto;

  background-color: #f8f8f8;

  display: -webkit-flex;

  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.statsIcon img {
  width: 20px;
}

.bottom {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  background-color: #f8f8f8;
  border-radius: 0 0 10px 10px;
  padding: 0.5rem;
  min-height: 5rem;
}

.bottom p {
  margin: 0;
  margin-bottom: 0.5rem;
}

.between {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.green {
  color: #1bc865;
}

.yellow {
  color: #e6c850;
}

.red {
  color: red;
}

.latestRates {
  display: -webkit-flex;
  display: flex;

  -webkit-flex-direction: column;

          flex-direction: column;
  grid-gap: 0.7rem;
  gap: 0.7rem;

  background-color: white;
  box-shadow: 0px 0px 3px 2px rgba(0, 0, 0, 0.1);

  max-width: 55rem;
  margin: 4rem auto;

  border-radius: 10px;
  padding: 2rem;
}

.latestRates h2 {
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
}

.rates {
  width: 100%;
}

.rate {
  width: 100%;
  font-size: 1rem;
  padding: 0 0.3rem 0.3rem 0.3rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  display: -webkit-flex;
  display: flex;
  margin-top: 5px;
}

.rate .span25 {
  min-width: 25%;
  max-width: 25%;
}

.rate .span20 {
  min-width: 20%;
  max-width: 20%;
}

.classification {
  font-weight: 600;
  padding: 2px;
  display: block;
  text-align: center;

  background-color: #1bc865;
}

.bgGreen {
  background-color: #1bc865;
}

.bgYellow {
  background-color: #e6c850;
}

.bgRed {
  background-color: #ff6565;
}

.latestComments {
  background-color: white;
  box-shadow: 0px 0px 3px 2px rgba(0, 0, 0, 0.1);

  max-width: 55rem;
  margin: 4rem auto;

  border-radius: 10px;
  padding: 2rem;
}

.latestComments h2 {
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  font-weight: 500;
}

.latestComments p {
  font-weight: normal !important;
  text-align: center;
  font-size: 1.7rem;
}

.positive {
  background-color: #5ee497;
  width: 100%;
  min-width: 30rem;
}

.negative {
  width: 100%;
  min-width: 30rem;
  background-color: #ffb8b3;
}

.phrases {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  padding: 0 1rem 1rem 1rem;
}

.phrase {
  max-width: 70ch;
  font-size: 1rem;
  font-weight: 600;
  padding-right: 3rem;

  text-align: justify;
}

.userData {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  text-align: right;
}

@media only screen and (max-width: 840px) {
  .rate {
    display: block;
  }

  .rate .span20, .rate .span25 {
    min-width: none;
    max-width: 100%;
    margin: 5px;
    display: block;
  }

  .saudacao div span {
    font-size: 12px;
    width: 100%;
  }
}

@media screen and (max-width: 960px){
  .saudacao div span {
    font-size: 16px;
    
  }
}

@media screen and (max-width: 370px){
  .saudacao div span {
    font-size: 14px;
    width: 100%;
    text-align: center;
  }
}



.login-page {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #f0f2f5;
}

.wrapper {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  overflow: hidden;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.container {
  width: 90%;
  max-width: 400px;
  margin: 0 auto;
  padding: 40px;
  text-align: center;
  background: #fff;
}
.container .logo-login {
  height: 70px;
  width: 70px;
  margin: 0 0 10px;
}

.container .ant-input-affix-wrapper {
  text-align: left;
  margin: 10px 0 10px;
}

.container button {
  margin: 15px 0 0;
  background-color: #0a2851;
}

.imageContent {
  width: 100%;
  margin: 0 0 10px;
}

.container-image {
  width: 80%;
  max-width: 600px;
  margin: 0 auto;
  padding: 40px;
  text-align: center;
  background: #fff;
}

.imageContent {
  width: 100%;
  margin: 0 0 10px;
}

.container-image {
  width: 80%;
  max-width: 600px;
  margin: 0 auto;
  padding: 40px;
  text-align: center;
  background: #fff;
}

.ant-form-item {
  margin-bottom: 21px !important;
}

.ant-btn-primary {
  background: #0a2851 !important;
}

.ant-switch-checked {
  background: #0a2851 !important;
}

.container-form {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  margin-top: 50px;
}

.content-left {
  height: 100vh;
}

.content-left .link-blue {
  margin: 10px 20px;
}

.content-right {
  height: 100vh;
}

.registerUser {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  margin-top: 150px;
}

.form-page {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff;
}

.wrapper {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  overflow: hidden;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.container {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  padding: 40px;
  text-align: center;
  background: #fff;
}
.container .logo-login {
  height: 70px;
  width: 70px;
  margin: 0 0 10px;
}

.logo-blue {
  height: 70px;
  width: 70px;
  margin: 0 0 10px;
}

.container .ant-input-affix-wrapper {
  text-align: left;
  margin: 10px 0 10px;
}

.container button {
  margin: 15px 0 0;
  background-color: #0a2851;
  width: 100%;
}

.title-welcome {
  font-size: 40px;
  font-weight: 100;
}

.subtitle-welcome {
  font-size: 20px;
  font-weight: 300;
}

@media screen and (max-width: 600px) {
  .title-welcome {
    font-size: 20px;
    font-weight: 100;
  }
  .subtitle-welcome {
    font-size: 18px;
    font-weight: 300;
  }
  .contentImage {
    display: none;
  }
}

@media screen and (max-width: 400px) {
  .title-welcome {
    font-size: 20px;
    font-weight: 100;
  }
  .subtitle-welcome {
    font-size: 18px;
    font-weight: 300;
  }
  .contentImage {
    display: none !important;
  }
}

.content {
  margin: -10px 16px 24px;
  padding: 24px;
  height: 100%;
}

.table .ant-table-row {
  background: #fff;
}

.table-actions {
  padding: 6px 12px;
  margin: -3px 0;
  cursor: pointer;
  transition: all 0.25s;
}

.table-actions:hover {
  color: #0cd28f;
}

.nopadding-col div {
  padding: 0;
}

.col-action {
  text-align: right !important;
}

.new-item {
  margin: 77px 16px 0px !important;
  padding: 12px !important;
  text-align: right;
}

.btn-add {
  width: inherit;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (max-width: 800px) {
  .content {
    padding: 0px;
  }
}
@media (max-width: 768px) {
  .new-item {
    margin: 0px 16px 20px !important;
    text-align: left;
  }
  .btn-add {
    width: 100%;
  }
}

@media (max-width: 576px) {
  .hidden-xs {
    display: none;
  }
}

.content {
  margin: -10px 16px 24px;
  padding: 24px;
  height: 100%;
}

.table .ant-table-row {
  background: #fff;
}

.table-actions {
  padding: 6px 12px;
  margin: -3px 0;
  cursor: pointer;
  transition: all 0.25s;
}

.table-actions:hover {
  color: #0cd28f;
}

.nopadding-col div {
  padding: 0;
}

.col-action {
  text-align: right !important;
}

.new-item {
  margin: 77px 16px 0px !important;
  padding: 12px !important;
  text-align: right;
}

.btn-add {
  width: inherit;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ant-card-actions {
  background: #ececec !important;
  border-top: 1px solid #fafafa !important;
}

.ant-card-head {
  border-bottom: 1px solid #fafafa !important;
}

@media (max-width: 800px) {
  .content {
    padding: 0px;
  }
}
@media (max-width: 768px) {
  .new-item {
    margin: 0px 16px 20px !important;
    text-align: left;
  }
  .btn-add {
    width: 100%;
  }
}

@media (max-width: 576px) {
  .hidden-xs {
    display: none;
  }
}

.content {
  margin: -10px 16px 24px;
  padding: 24px;
  height: 100%;
}

.table .ant-table-row {
  background: #fff;
}

.table-actions {
  padding: 6px 12px;
  margin: -3px 0;
  cursor: pointer;
  transition: all 0.25s;
}

.table-actions:hover {
  color: #0cd28f;
}

.nopadding-col div {
  padding: 0;
}

.col-action {
  text-align: right !important;
}

.new-item {
  margin: 77px 16px 0px !important;
  padding: 12px !important;
  text-align: right;
}

.btn-add {
  width: inherit;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (max-width: 800px) {
  .content {
    padding: 0px;
  }
}
@media (max-width: 768px) {
  .new-item {
    margin: 0px 16px 20px !important;
    text-align: left;
  }
  .btn-add {
    width: 100%;
  }
}

@media (max-width: 576px) {
  .hidden-xs {
    display: none;
  }
}


/* .ant-table-row {
  background-color: blue !important;
} */


.clickRowStyl {
  background-color:#00000040 !important;
  font-size: 12px !important;
}
.rowComment {
  font-size: 12px !important;
}
/* .ant-table-tbody>.clickRowStyl:hover>td {
  background-color: '#00b4ed' !important
} */

.linkwhatsapp {
  background-image: url(/static/media/whatsapp-icon.188e037c.svg);
  background-repeat: no-repeat;
  background-position-x: 0;
  padding-top:3px;
  padding-bottom:3px;
  padding-left: 28px;
}


.content {
  margin: -10px 16px 24px;
  padding: 24px;
  height: 86vh;
}

.content-blue {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  margin-top: 100px;
}

.content-blue h1 {
  font-size: 60px;
  font-weight: bold;
  letter-spacing: 5px;
}

@media (max-width: 800px) {
  .content-blue h1 {
    font-size: 2em;
  }
}

.home-charts {
  margin-top: 5px;
  margin-bottom: 5px;
}

.home-charts div {
  background: #fff;
  border: 1px solid #e8e8e8;
  margin-top: -5px;
  margin-bottom: -5px;
}

.ant-card-head-title {
  font-size: 14px !important;
  font-weight: 700;
}

.ant-layout.ant-layout-has-sider {
  -webkit-flex-direction: row;
  flex-direction: row;
  height: 100vh;
}

/* .anticon {
  color: #fff !important;
} */

.ant-layout-sider {
  background: #0a2851 !important;
}

.ant-layout-sider-collapsed .logo {
  width: 50px !important;
  transition: all 0.15s;
}

.welcome {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.logo {
  height: 28px;
  margin: 16px;
  /* width: 165px !important; */
}

.logoWelcome {
  height: 65px;
  margin: 16px;
}

.logo .logo-image {
  margin-top: 0px;
  height: 32px;
  /* width: 165px; */
}

.logo .logo-icon {
  height: 50px;
  width: 50px;
  margin-left: 0px;
  margin-top: -10px;
}

.ant-menu-dark,
.ant-menu-dark .ant-menu-sub {
  background: #0a2851 !important;
}

@media (max-width: 800px) {
  .content {
    padding: 0px;
  }
}

@media (min-width: 992px) {
  .custom-col-5 {
    width: 20%;
  }
}


.ant-layout-content {
  -webkit-flex: auto;
  flex: auto;
  min-height: auto !important;
}

.ant-collapse > .ant-collapse-item:last-child {
  border-radius: 0 0 4px 4px;
  font-size: 12px;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  font-size: 12px;
}

.card-redes-sociais{
    background-color: #cfe8f5 !important;
}

.card-redes-sociais input {
    margin-bottom: 1rem;
}

.ant-checkbox-wrapper{
    margin-top: 0.3rem !important;
}
.upload-logo{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    width: 100%;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.upload-logo img {
    margin-top: 10px;
    margin-bottom: 10px;
    width: 200px;
    height: 120px;
}

.upload-logo input {
    visibility: hidden;
    cursor: pointer;
}

.upload-logo label {
    cursor: pointer;
    text-decoration: underline;
    font-weight: bold;
    color: #0a2851;
    font-size: 16px;
}

.upload-logo label:last-child {
    color: red;
}
.form-content-template {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    -webkit-align-items: flex-start;
            align-items: flex-start;
}

.form-template{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    width: 50%;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    border-right: 1px solid #ccc;
    padding: 10px;
}

.form-template label {
    font-weight: bold;
}

.form-template label.delete {
    font-weight: bold;
    color: red;
    cursor: pointer;
    text-decoration: underline;
    font-size: 16px;
}

.form-template img {
    width: 50%;
    height: 50%;
}

.form-template span.info {
    font-style: italic;
}
.content {
  margin: -10px 16px 24px;
  padding: 24px;
  height: 100%;
}

.table .ant-table-row {
  background: #fff;
}

.table-actions {
  padding: 6px 12px;
  margin: -3px 0;
  cursor: pointer;
  transition: all 0.25s;
}

.table-actions:hover {
  color: #0cd28f;
}

.nopadding-col div {
  padding: 0;
}

.col-action {
  text-align: right !important;
}

.new-item {
  margin: 77px 16px 0px !important;
  padding: 12px !important;
  text-align: right;
}

.btn-add {
  width: inherit;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ant-card-actions {
  background: #ececec !important;
  border-top: 1px solid #fafafa !important;
}

.ant-card-head {
  border-bottom: 1px solid #fafafa !important;
}

@media (max-width: 800px) {
  .content {
    padding: 0px;
  }
}
@media (max-width: 768px) {
  .new-item {
    margin: 0px 16px 20px !important;
    text-align: left;
  }
  .btn-add {
    width: 100%;
  }
}

@media (max-width: 576px) {
  .hidden-xs {
    display: none;
  }
}


.row-price {
  display: -webkit-flex !important;
  display: flex !important;
  -webkit-align-items: center !important;
          align-items: center !important;
  -webkit-justify-content: flex-end !important;
          justify-content: flex-end !important;
}

.card-header .ant-card-head {
  background: #0a2851 !important;
}

.card-header .ant-card-head-title {
  color: #FFF !important
}

.steps-content {
	margin-top: 16px;
	border: 1px dashed #e9e9e9;
	border-radius: 6px;
	background-color: #fafafa;
	min-height: 560px;
	text-align: center;
	padding-top: 80px;
}

.steps-action {
	margin-top: 24px;
  margin-bottom: 40px;
}

@media (max-width: 500px) {
	.ant-steps-horizontal.ant-steps-label-horizontal {
		display: -webkit-flex !important;
		display: flex !important;
	}
}
@media (max-width: 480px) {
	.ant-steps-item-tail, .ant-steps-item-title, .ant-steps-item-content {
		display: none !important;
	}
}

.indice-blue {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: row;
	        flex-direction: row;
	-webkit-justify-content: space-between;
	        justify-content: space-between;
	cursor: pointer;
	background: #ff3232; /* Old browsers */ /* FF3.6-15 */ /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to right,  #ff3232 0%,#ccc620 50%,#00bc06 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff3232', endColorstr='#00bc06',GradientType=1 ); /* IE6-9 */
}

.indice-blue-item {
	-webkit-flex: 1 1;
	        flex: 1 1;
	color: #fff;
	padding: 5px;
	border: #fff 1px solid;
	text-align: center;
}

.indice-blue-item.selected {
	font-weight: 900;
	background: rgba(255, 255, 255, .5);
	color: #fff;
}

.next-step:disabled {
  background: #eee !important;
  color: #aaa !important;
  border-color: #aaa !important;
}

.btn-rede-social{
    background-color: transparent !important;
    border: none !important;
    margin-top: 18px;
    cursor: pointer;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.btn-avaliacao-externa{
    margin-bottom: 12px;
    -webkit-flex-direction: column;
            flex-direction: column;
}

.btn-avaliacao-externa label {
    font-size: 13px;
    width: 100%;
    display: block;
    text-align: left;
}

.btn-rede-social img {
    width: 150px;
    border-radius: 6px;
}

.btn-rede-social img:hover{
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.div-follow-us{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    max-width: 600px;
    margin: auto;
}

.follow-us img{
    width: 45px;
    height: 45px;
}

.follow-us label {
    font-size: 16px;
    cursor: pointer;
}

.orientacao-rede-social {
    font-size: 12px;
    margin-top: 10px;
    font-weight: bold;
}

@media screen and (max-width: 480px) {
    .div-follow-us {
        -webkit-align-items: flex-start;
                align-items: flex-start;
    }
    .btn-rede-social img {
        width: 100px;
    }

    .follow-us img{
        width: 45px;
        height: 45px;
    }
}
.logo {
  height: 28px;
  margin: 16px;
  /* width: 165px !important; */
  text-align: center;
}

.logo .logo-image {
  margin-top: 0px;
  height: 32px;
  /* width: 165px; */
}

.logo .logo-icon {
  height: 50px;
  width: 50px;
  margin-left: 0px;
  margin-top: -10px;
}

.App {
  text-align: center;
}

.ant-message, .ant-popover {
  z-index: 999999999 !important;
}

.logo-cliente {
  height: auto; 
  width: auto; 
  max-width: 200px; 
  max-height: 120px;
}

